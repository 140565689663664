import type { ReactNode } from "react";
import React, { memo } from "react";
import { Divider } from "antd";
import Image from "next/image";
import type { ModalBaseProps } from "@aspen/ui/modalBase";
import { ModalBase } from "@aspen/ui/modalBase";
import styles from "./index.module.less";

export const ic_success = require("@aspen/assets/images/profile/email.webp");
export const ic_fail = require("@aspen/assets/images/profile/google.webp");
export const ic_pending = require("@aspen/assets/images/profile/phone.webp");

interface ListItem {
  label: ReactNode;
  value: ReactNode;
}
const areEqual = (prevProps: IProps, nextProps: IProps) => {
  return prevProps.open === nextProps.open;
};
interface IProps extends ModalBaseProps {
  resultType?: "success" | "fail" | "pending";
  title?: string;
  titleTopMargin?: number;
  titleBottomMargin?: number;
  text?: string;
  textAlign?: "left" | "center";
  divider?: boolean;
  list?: ListItem[];
}

export const resultIconSrc = {
  success: ic_success,
  fail: ic_fail,
  pending: ic_pending
};

const ModalResult: React.FC<IProps> = memo((props) => {
  const {
    resultType = "success",
    title,
    titleTopMargin = 80,
    titleBottomMargin = 32,
    text,
    textAlign = "left",
    divider,
    list,
    children,
    ...rest
  } = props;

  return (
    <ModalBase buttonType="only" {...rest}>
      <div className={styles.resultModal}>
        <div
          className="text-center"
          style={{ margin: `${titleTopMargin}px 0 ${titleBottomMargin}px` }}>
          <Image src={resultIconSrc[resultType]} alt="" width={80} height={80} />
          {title ? <p className={styles.title}>{title}</p> : null}
        </div>
        {text ? (
          <p className={styles.tip} style={{ textAlign }}>
            {text}
          </p>
        ) : null}
        {divider ? <Divider /> : null}
        {list ? (
          <>
            <div className={styles.list}>
              {list.map(
                (item, index) =>
                  item.value && (
                    <div className={styles.item} key={index}>
                      <div className={styles.label}>{item.label}</div>
                      <div className={styles.number}>{item.value}</div>
                    </div>
                  )
              )}
            </div>
            <Divider className={styles.divider} />
          </>
        ) : null}
        {children ? children : null}
      </div>
    </ModalBase>
  );
}, areEqual);
export { ModalResult };
