"use client";
import React, { forwardRef, useImperativeHandle, useState } from "react";
import { message, ModalBase } from "@aspen/ui";
import Image from "next/image";
import Bugsnag from "@bugsnag/js";

import { AppkeyAlicloud, formatBugsnagMessage, i18nUtil } from "@aspen/libs";
import logo from "@aspen/assets/images/aspen_logo_white.png";
import styles from "./index.module.less";

const AlicloudNCCaptcha = (props, ref) => {
  const intl = i18nUtil.t();
  const [visible, setVisible] = useState<boolean>(false);
  const init = (scene, successCallback) => {
    if (typeof window != "undefined") {
      window?.AWSC?.use("nc", (state, module) => {
        // state标识状态，首次实例化会加载外部js，timeout为获取超时，loaded为已加载
        if (state === "timeout") {
          message.error(intl["alicloud.loading.timeout.tip"]);

          console.log("alicloud nc loading timeout");
          Bugsnag.notify(
            new Error(
              formatBugsnagMessage(
                "Alicloud sliding verification JS loading timeout",
                "Action-sliding-verification"
              )
            )
          );
        }

        let failCount = 0;
        // 初始化
        window.nc = module.init({
          appkey: AppkeyAlicloud,
          scene,
          renderTo: "nc",
          language: "en",
          upLang: {
            en: intl["alicloud.sliding.verification.uplang.en"]
          },
          hideErrorCode: true,
          //前端滑动验证通过时会触发该回调参数。您可以在该回调参数中将会话ID（sessionId）、签名串（sig）、请求唯一标识（token）字段记录下来，随业务请求一同发送至您的服务端调用验签。
          success: (data) => {
            window.console && console.log(data);
            successCallback(data);
          },
          // 滑动验证失败时触发该回调参数。
          fail: (failCode) => {
            // 连续失败三次后，提示用户刷新页面
            failCount++;
            if (failCount >= 3) {
              message.error(intl["afs.nvc.fail.refresh"]);
            }

            Bugsnag.notify(
              new Error(
                formatBugsnagMessage(
                  `Alicloud sliding verification failed, failCode: ${failCode}`,
                  "Action-sliding-verification"
                )
              )
            );
          },
          // 验证码加载出现异常时触发该回调参数。
          error: (errorCode) => {
            window.console && console.log(errorCode);
            Bugsnag.notify(
              new Error(
                formatBugsnagMessage(
                  `Alicloud sliding verification loading exception, errorCode: ${errorCode}`,
                  "Action-sliding-verification"
                )
              )
            );
          }
        });
      });
    }
  };

  const reset = () => {
    typeof window != "undefined" && window?.nc?.reset && window.nc.reset();
  };

  useImperativeHandle(ref, () => ({
    init,
    reset,
    setVisible
  }));

  return (
    <ModalBase
      style={{ background: "#23262F" }}
      open={visible}
      footer={false}
      onCancel={() => setVisible(false)}>
      <div className={styles.alicloudNc}>
        <span className={styles.logo}>
          <Image unoptimized src={logo} alt="" width={158} height={73} />
        </span>
        <p className={styles.title}>{intl["login.title"]}</p>
        <div id="nc" style={{ margin: "32px 0" }} />
      </div>
    </ModalBase>
  );
};

export default React.memo(forwardRef(AlicloudNCCaptcha));
